@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

#box {
  @media (max-width: 1078px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      width: 80%;
    }
  }

  @media (max-width: 990px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      width: 100%;
      margin-top: -10px;
    }
  }
}

.prototype {
  max-width: 550px;
  max-height: 500px;
  width: auto;
  height: auto;
}

.roadmap {
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.container-services {
  margin-bottom: 400px;
}

.row-container {
  display: flex;
  flex-direction: row;
}

@media (max-width: 766px) {
  .row-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

#coluna {
  max-width: 300px;
  background-image: url(../img/restaurantCD.jpg);
  height: 35rem;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

}
#coluna1 {
  max-width: 300px;
  background-image: url(../img/QRFolder.png);
  height: 35rem;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

#coluna2 {
  max-width: 300px;
  background-image: url(../img/Qr-code.jpg);
  height: 35rem;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

#coluna3 {
  max-width: 300px;
  background-image: url(../img/development-card.png);
  height: 35rem;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

#link-services {
  width: 300px;
  
}

#coluna:hover,
#coluna1:hover,
#coluna2:hover,
#coluna3:hover {
opacity: 
0.8;
background-color: black

}

#link-services:hover,
#coluna:hover,
#coluna1:hover,
#coluna2:hover,
#coluna3:hover {
  width: 350px;
  height: 37rem;
  transition: 0.6s;
 
  
}

.title-service {
  opacity: 0;
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 27px;
  text-shadow: 1px 1px 2px black;
}

#coluna:hover .title-service,
#coluna1:hover .title-service,
#coluna2:hover .title-service,
#coluna3:hover .title-service {
  opacity: 1;
  transition: 0.6s;
}
