.more-link {
  background-color: white;
  border: 0;
  color: $living-coral;
  margin-top: 2rem;

  .icon {
    margin-left: 8px;
    transform: translateX(0);
    transition: transform 0.3s ease-out;
  }

  &:hover {
    background-color: $living-coral-light-5x;
    color: $living-coral;
    box-shadow: 0 5px 10px $living-coral;

    .icon {
      transform: translateX(5px);
    }
  }
}
