@import "themes/living-coral/variables/override";

.card {
  margin-top: 30px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  display: flex;
  text-align: center;

}

.image-right {
  position: 'absolute';
  margin-left: '40px';
}

.camera-icon {
    margin-bottom: 5px;
}

.black {
  color: black
}

.process-container {
  background-color: $living-coral;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: white;
  padding-top: 75px;
  padding-bottom: 25px;

  .heading-line {
    &:after {
      content: "";
      display: block;
      margin-top: 32px;
      margin-bottom: 24px;
      width: 40px;
      height: 2px;
      background-color: white;
    }
  }
}

.process-session {
  width: 100%;
  justify-content: center;
  align-items: center;

  .col {
    padding: 0px;
    margin: 0px;
  }

}

.content {
  padding: 0px 300px;
  width: 100%;
  margin-top: 60px;

  img {
    width: 200px;
    height: 200px;
  }
  
  .col {
    width: 100%;
    margin-left: 60px;
    margin-right: 100px;
  }
}

@media only screen and (max-width: 1024px) {
  .content {
    padding: 0px 200px;
    width: 100%;
    margin-top: 60px;

    img {
      width: 200px;
      height: 200px;
    }
    
    .col {
      width: 100%;
      margin-left: 60px;
      margin-right: 100px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .content {
    padding: 0px;
    width: 100%;
    margin-top: 60px;

    img {
      width: 200;
      height: 200px;
    }
    
    .col {
      width: 100%;
      margin-left: 60px;
      margin-right: 100px;
    }
  }
}