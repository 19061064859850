@font-face {
  font-family: 'Autography';
  src: url('./Autography.eot?#iefix');
  src: url('./Autography.eot') format('embedded-opentype'), url('./Autography.woff') format('woff'), url('Autography.ttf') format('truetype');
  font-weight: normal;
  font-style: normal; 
}

.font-autography {
  font-family: Autography;
  color: #ed2532;
  text-shadow: 2px 2px 5px #ccc;
}
